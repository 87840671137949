export const generateHeadScript = (src: string): Promise<string> => {
  if (document.querySelector(`script[src="${src}"]`)) {
    return Promise.resolve(src);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.setAttribute("type", "text/javascript");

    if (script) {
      script.onload = () => resolve(src);
      script.onerror = () => reject(src);
      document.head.appendChild(script);
    }
  });
};

export const generateBodyScript = (src: string): Promise<string> => {
  if (document.querySelector(`script[src="${src}"]`)) {
    return Promise.resolve(src);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.setAttribute("type", "text/javascript");

    if (script) {
      script.onload = () => resolve(src);
      script.onerror = () => reject(src);
      document.body.appendChild(script);
    }
  });
};

export const deleteScript = (src: string) => {
  const el = document.querySelector(`script[src="${src}"]`);
  el && el.remove();
};

export const generateHeadLink = (href: string): Promise<string> => {
  if (document.querySelector(`link[href="${href}"]`)) {
    return Promise.resolve(href);
  }

  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.setAttribute("href", href);
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");

    if (link) {
      link.onload = () => resolve(href);
      link.onerror = () => reject(href);
      document.head.appendChild(link);
    }
  });
};

export const generateBodyLink = (href: string): Promise<string> => {
  if (document.querySelector(`link[href="${href}"]`)) {
    return Promise.resolve(href);
  }

  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.setAttribute("href", href);
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");

    if (link) {
      link.onload = () => resolve(href);
      link.onerror = () => reject(href);
      document.body.appendChild(link);
    }
  });
};

export const deleteLink = (href: string) => {
  const el = document.querySelector(`link[href="${href}"]`);
  el && el.remove();
};
